
import "@fontsource/roboto-condensed"
import "@fontsource/open-sans"
//import qs from 'qs';
import './App.css';
import {useContext} from 'react';
import Navbar from './navbar';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import './i18n/i18n';
//import {useParams} from "react-router-dom";
//import { useState, useEffect } from "react";
//import PracticeContent from './practice_content';
import PageWithLangSupport from "./page_with_lang_support";
import { UserContext } from './glob_context';
//import { AiFillMinusSquare, AiFillPlusSquare, AiOutlineGlobal, AiOutlineHome, AiOutlineCopyrightCircle, AiOutlineMail} from 'react-icons/ai';


function App(props) {
  
  const [globLang /*, setGlobLang, globPage, setGlobPage*/] = useContext(UserContext);
  const {t, i18n} = useTranslation();
  
  let lang = i18n.currentLanguage;
  //let paramLang = useParams()['lang'];
  if(!lang) {
    lang = 'fi';
  }

  //const qlang = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).l;
  //const qpage = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).p;
  
  
  return (
    <>
    <div className="App">
      <header className="App-header">
        
        <h1 id="main-heading">{t('general.art_name') + ' ' + t('general.country')}</h1>
        
        <div id="h1im"><h3 id="location">{t('general.municipality')}</h3></div>
      </header>
    </div>
    <hr></hr>
   
      <Navbar />
      <hr></hr>
      <div className="text-part">
        <Switch>
          <Route path='/' exact>
            <Redirect to={'/fi/main'} />
          </Route> 
          <Route path='/:lang/:page'>
            <PageWithLangSupport />
          </Route>
        </Switch>
      </div>
    
  
    <div className="imstrip">
    </div>
    <footer>
        <hr></hr>
        <div className="left">
          <span className="author-name">
            {t('general.author')}
          </span>
          <span className="show-when-little-vertical-space-space"></span>
          <span>
            <br className="hide-when-little-vertical-space author-name"></br>
            <Link to={'/' + globLang + '/privacy'} >{t('general.privacy')}</Link>
            {/*<a href={'/' + globLang + '/privacy'}>{t('general.privacy')}</a>*/}
          </span>
          {/*}
          <a href={'mailto:' + t('general.email')}><span><AiOutlineMail className="symbol-mail"/>{t('general.email')}</span></a>
          {*/}
        </div>
        <div className="right">
          {t('general.updated')} {t('general.update_date')}
        </div>
    </footer>
    </>
  );
}

export default App;
