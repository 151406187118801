import React, {useState} from 'react';
//import { AiFillMinusSquare, AiFillPlusSquare} from 'react-icons/ai';
import { BsPlusSquareFill as PlusChar, BsFillDashSquareFill as MinusChar} from 'react-icons/bs';

  
const ExpandableSection = (props) => {
  
  const [expanded, setExpanded] = useState(props.showInitially);
  let styleDef = {"display": (expanded ? "block" : "none")};
  const expandSymbol = props.noExpandSymbol ? '' : (expanded ? <MinusChar /> : <PlusChar />);

  const headingPartClasses = `centered-label tinted2 ${expanded ? 'bottom-border-fix' : ''}`

  return (
    <div className={props.heading ? 'tinted' : 'no-header'}>

      <div className={headingPartClasses} style={{display: props.heading ? 'block' : 'none'}}>
        <table className="exp-header-table" onClick={() => {setExpanded(!expanded)}}>
          <tbody>
            <tr>
              <td className="td0">
                <h3 className="pointerCursor">
                  {props.heading}
                </h3>
              </td>
              <td className="td1">
                <span className={expanded ? "symbol-contract" : "symbol-expand"}> {expandSymbol}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style={styleDef} className="expandableSection">
        {props.content}
      </div>

    </div>
  );
};
  
export default ExpandableSection;