
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fi from "./fi";
import en from "./en";
//import test from "./test";
//import foo from "./foo";


const resources = {
  fi: fi,
  en: en/*,
  test: test,
  foo: foo*/
};


//console.log('LANG PARAM: ' + lang);
//if (i18n.exists('fi') === false) {
if(!window.currentLanguage) {
  window.currentLanguage = 'fi';
}
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: window.currentLanguage,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    keySeparator: '.'
  });

//}

export default i18n;
