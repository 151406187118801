import React, { useEffect, useState } from "react";
//import ContactFormModal from "./ContactFormModal";
//import Modal from 'react-bootstrap/Modal';
//import Button from 'react-bootstrap/Button';

const maxChars = "2000";
const wideFormModeThreshold = 992;

const ContactForm = (props) => {
  const t = props.t;
  const [status, setStatus] = useState("ready");
  const [charsLeft, setCharsLeft] = useState(maxChars);
  const [narrowFormMode, setNarrowFormMode] = useState(window.innerWidth < wideFormModeThreshold);
  const [sentStatus, setSentStatus] = useState('');
  
  const onMessageChanged = (e) => {
    
    let l = e.target.value.length;
    if(l > maxChars) {
      e.target.value = e.target.value.substring(0, maxChars);
    }
    else {
      setCharsLeft(maxChars - l);
    }
    
  }


  useEffect(() => {

    function handleWindowResize() {
      //console.log(window.innerWidth);
      if (window.innerWidth < wideFormModeThreshold) {
        setNarrowFormMode(true);
      }
      else if (window.innerWidth >= wideFormModeThreshold) {
        setNarrowFormMode(false);
      }
      
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };

  }, []);


  const handleSubmit = async (e) => {
    //console.log('handling submit');
    e.preventDefault();
    setStatus("sending");
    const { name, email, message, hum } = e.target.elements;
    let content = {
      name: name.value,
      email: email.value,
      message: message.value,
      humanity: hum.value      
    };
    //console.log(content);
    content = JSON.stringify(content);
    //console.log(content);
    let response = await fetch("https://tampere.kssr.fi/message", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: content,
    });
    setStatus(t('ready'));
    let result = await response.json();
    //console.log(result);
    //const notification = t('contact.' + result.status);
    //alert(notification);
    setSentStatus(result.status);
  };

  
  

  return (
    <>
      <div id="message-status-area" className={!sentStatus ? "do-not-show" : "status-" + sentStatus}>
          {t('contact.' + sentStatus)}
      </div>
      <div className={sentStatus ? "do-not-show" : ""}>
      <form className="inline-input-container" onSubmit={handleSubmit}>
        
        <label htmlFor="name">{t('contact.name')}</label>
        <br className="hide-when-wide"></br>
        <input maxLength="70" className={narrowFormMode ? "hide-when-wide" : "show-when-wide inline-input"} type="text" id="name" required /> 
        <br></br>
        <br></br>
        <label htmlFor="email">{t('contact.email')}</label>
        <br className="hide-when-wide"></br>
        <input maxLength="70" className={narrowFormMode ? "hide-when-wide" : "show-when-wide inline-input"} type="email" id="email" required />
        <br></br>
        <br></br>
        <label htmlFor="message">{t('contact.msg')}</label>
        <div className="msg-chars">
          {charsLeft}/{maxChars} {t('contact.chars_left')}
        </div>
        <br></br>
        <div className="center">
          <textarea onChange={onMessageChanged} maxLength={maxChars} rows="4" id="message" required />
        </div>
        <br></br>
        <label htmlFor="hum">{t('contact.humanity_check')}</label>
        <br className="hide-when-wide"></br>
        <input maxLength="70" className={narrowFormMode ? "hide-when-wide" : "show-when-wide inline-input"} type="text" id="hum" required />  
        <div className="hum-check-instr">{t('contact.hum_check_instr')}</div>
        <br></br>
        <div className="center">
          <button className="sendFormButton" type="submit">{status === 'ready' ? t('contact.submit') : t('contact.sending')}</button>
        </div>
        
      </form>
      </div>
    </>
  );
};

export default ContactForm;