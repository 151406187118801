import React, {useEffect, useContext} from 'react';
//import logo from './shotou_.png';
import {useTranslation} from 'react-i18next';
import { UserContext } from './glob_context';
import {useParams} from "react-router-dom";
import PracticeContent from './practice_content';
import MainContent from './mainpage_content';
import PrivacyContent from './privacy_content';
import ContactContent from './contact_content';

const PageWithLangSupport = (props) => {
  
  const {t, i18n} = useTranslation();
  const [globLang, setGlobLang, globPage, setGlobPage] = useContext(UserContext);
  
  

  const pars = useParams();
  let paramLang = pars['lang'];
  let paramPage = pars['page'];
  //console.log('got ' + paramLang + ' from url on page ' + paramPage);
  let initLangFromUrl = '';
  if (paramLang === 'fi' || paramLang === 'en' /* || paramLang === 'foo' || paramLang === 'test'*/) {
    initLangFromUrl = paramLang;
  }
  //console.log(initLangFromUrl);
  

  useEffect(() => {
    if(initLangFromUrl && initLangFromUrl !== globLang){
      //console.log('change language based on url: ' + initLangFromUrl);
      i18n.changeLanguage(initLangFromUrl).then(setGlobLang(initLangFromUrl));
      return(<></>);
    }
    else {
      
      //i18n.changeLanguage(initLangFromUrl)
          /*.then((t) => {
            setGlobLang(initLangFromUrl);
          }
      );*/
    }     
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [initLangFromUrl/*, globLang*/]);

  //console.log(paramPage + ' with language ' + initLangFromUrl + ' ordered');


  /*
  if(initLangFromUrl !== globLang) {
    return (
      <>
        <div className="text-content">
          Loading language
        </div>
      </>
    );
  }
  */

  if(paramPage === 'main') {
    if(globPage !== 'main') {
      setTimeout(()=>{setGlobPage('main')}, 0);
    }
    return(<MainContent t={t}/>);
  }
  else if(paramPage === 'practice') {
    if(globPage !== 'practice') {
      setTimeout(()=>{setGlobPage('practice')}, 0);
    }
    return(<PracticeContent t={t}/>);
  }
  else if(paramPage === 'privacy') {
    if(globPage !== 'privacy') {
      setTimeout(()=>{setGlobPage('privacy')}, 0);
    }
    return(<PrivacyContent t={t}/>);
  }
  else if(paramPage === 'contact') {
    if(globPage !== 'contact') {
      setTimeout(()=>{setGlobPage('contact')}, 0);
    }
    return(<ContactContent t={t}/>);
  }

  return(<MainContent t={t}/>);
};
  
export default PageWithLangSupport;