import React, { useState } from 'react';
export const UserContext = React.createContext('');

const GlobalContext = ({ subPages }) => {
    const [globLang, setGlobLang] = useState('');
    const [globPage, setGlobPage] = useState('main');
    //let globPage='fi';
    //let setGlobPage=()=>{};
    return (
        <UserContext.Provider value={[globLang, setGlobLang, globPage, setGlobPage]}>
            {subPages}
        </UserContext.Provider>
    )
};
export default GlobalContext;