//import {useTranslation} from 'react-i18next';   
//import WithLanguageSupport from './with_language_support';   
function MainContent(props) {
  const t = props.t;
   
  return (
    <>
    {/*<Navbar setStateLang={setStateLang}/>*/}
    <div className="text-content">
      <h2>{t('main.h1')}</h2>
      <div className="columned">
        <p className="avoid-break">
          {t('main.p1')}
        </p>
        <p className="avoid-break">
          {t('main.p2')}
        </p>
        <p className="avoid-break">
          {t('main.p3')}
        </p>
      </div>
      {/*
      <div>
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      */}
      <h2>{t('contact.links_heading')}</h2>
      <ul>
        <li><a href={t('contact.kssr_budo_renmei_address')}>{t('contact.kssr_budo_renmei_text')}</a>{t('contact.kssr_budo_renmei_explanation')}</li>
        <li><a href={t('contact.kssr_hki_address')}>{t('contact.kssr_hki_text')}</a></li>
      </ul>
      <br></br>
    </div>
    
    </>
    
  );
};
  
export default MainContent;