import {useTranslation} from 'react-i18next';   
//import WithLanguageSupport from './with_language_support';   
import ExpandableSection from './ExpandableSection';


  const s3_fi = {
    content: <>
    Yhdistyksellä on erilaisia tarpeita henkilötietojen käsittelylle
  riippuen esimerkiksi siitä, kenen tietojen käsittelystä on kyse.
  Seuraavaan luetteloon on eritelty tarkoitukset, joita varten tietoja
  käsitellään. Lisäksi kerrotaan kussakin tapauksessa sovellettava
  oikeusperuste, joka mahdollistaa tietojen käsittelyn.
    <ul>
      <li>
        Jäsenviestintä
        <ul>
          <li>
            Jäsenille
      voidaan esimerkiksi viestiä ajankohtaisista tapahtumista ja
      harjoitusjärjestelyistä, hoitaa jäsenmaksuasioita sekä lähettää
      kokouskutsuja.
          </li>
          <li>
            Käsittelyn oikeusperuste: rekisteröidyn suostumus, osin (maksuliikenteen ja kokouskutsujen osalta) sopimuksen toteuttaminen
          </li>
        </ul>
      </li>
      <li>
        Yhdistyksen jäsenluettelon ja yhdistyksen toimintaan oleellisesti liittyvien jäsentietojen ylläpito
        <ul>
          <li>
            Yhdistyksen on pidettävä kirjaa jäsenistään. Esim. vyökoetiedot ja mahdolliset välinetilaustiedot ovat keskeisiä yhdistyksen toiminnan mahdollistamiseksi.
          </li>
          <li>
            Käsittelyn oikeusperuste:
            <ul>
              <li>
                nimen ja kotipaikan osalta lakisääteinen velvoite,
              </li>
              <li> 
              jäsenyyden lajin (liittyy kerättäviin maksuihin), sähköpostiosoitteen (liittyy kerättäviin maksuihin ja kokouskutsujen lähettämiseen), vyökoe-, tilaus- ja osallistumistietojen yms. (mahdollistavat yhdistyksen toiminnan tarkoitetusti) osalta sopimuksen toteuttaminen
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Yhdistyksen pöytäkirjojen ja muiden dokumenttien säilytys ja hallinnointi
        <ul>
          <li>
          Yhdistyksellä
      on velvollisuus säilyttää dokumentaatiota lain vaatimalla
      tavalla. Lisäksi yhdistyksen toimintaan liittyen saattaa olla
      luontevaa ja mielekästä esimerkiksi kerätä kuvapankkia, laatia
      esitteitä jne.
          </li>
          <li>
          Käsittelyn
      oikeusperuste: lakisääteinen velvoite, rekisteröidyn suostumus
          </li>
        </ul>
      </li>
      <li>
        Muiden lakisääteisten velvollisuuksien täyttäminen
        <ul>
          <li>
            Henkilötietoja voidaan käsitellä oikeusperusteella lakisääteinen velvoite
            <ul>
              <li>
              toiminnantarkastuksen suorittamiseksi ja
              </li>
              <li>
              kaikkien
        tähän erikseen kirjaamattomien lakisääteisten velvollisuuksien
        täyttämiseksi. Tällaisia voivat olla esimerkiksi kirjanpitolain
        vaatimukset sekä viranomaisten lakiin perustuva tietopyyntöjen
        käsittely.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Väärinkäytösten selvittäminen
        <ul>
          <li>
            Verkkopalvelinlokitietoja voidaan käyttää sivujemme laadunvarmistukseen (mahdollisten vikojen havaitsemiseen) sekä verkkohyökkäysten ja vastaavien rikosten ja väärinkäytösten selvittämiseen oikeusperusteella rekisterinpitäjän oikeutettu etu.
            <ul>
              <li>
                Asiaa koskevassa tasapainotestissä tultiin tulokseen, jonka mukaan tavanomainen palvelinlokien kerääminen
                palvelee niin verkkosivujen käyttäjien kuin rekisterinpitäjänkin etua eikä aiheuta kohtuutonta haittaa.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Viestintä	ulkopuolisten tahojen kanssa (esim. sähköpostikyselyihin vastaaminen)
        <ul>
          <li>
            Käsittelyn oikeusperuste: rekisterinpitäjän oikeutettu etu
            <ul>
              <li>
                Asiaa
        koskevassa tasapainotestissä tultiin tulokseen, jossa
        kommunikoinnin mahdollistuminen on niin rekisterinpitäjän kuin
        rekisteröidynkin etu. Käsittelyn kohteena ovat yhteystiedot,
        joita käytetään nimenomaan siten kuin rekisteröity niitä
        todennäköisesti käytettävän olettaa. Rekisteröity hyötyy
        siitä, että yhdistys voi esimerkiksi käsitellä
        sähköpostiosoitetta vastatakseen sille osoitettuun tiedusteluun.
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    </>
  };
  
  const s3_en = {
    content: <>
    Yhdistyksellä on erilaisia tarpeita henkilötietojen käsittelylle
  riippuen esimerkiksi siitä, kenen tietojen käsittelystä on kyse.
  Seuraavaan luetteloon on eritelty tarkoitukset, joita varten tietoja
  käsitellään. Lisäksi kerrotaan kussakin tapauksessa sovellettava
  oikeusperuste, joka mahdollistaa tietojen käsittelyn.
    <ul>
      <li>
        Jäsenviestintä
        <ul>
          <li>
            Jäsenille
      voidaan esimerkiksi viestiä ajankohtaisista tapahtumista ja
      harjoitusjärjestelyistä, hoitaa jäsenmaksuasioita sekä lähettää
      kokouskutsuja.
          </li>
          <li>
            Käsittelyn oikeusperuste: rekisteröidyn suostumus, osin (maksuliikenteen ja kokouskutsujen osalta) sopimuksen toteuttaminen
          </li>
        </ul>
      </li>
      <li>
        Yhdistyksen jäsenluettelon ja yhdistyksen toimintaan oleellisesti liittyvien jäsentietojen ylläpito
        <ul>
          <li>
            Yhdistyksen on pidettävä kirjaa jäsenistään. Esim. vyökoetiedot ja mahdolliset välinetilaustiedot ovat keskeisiä yhdistyksen toiminnan mahdollistamiseksi.
          </li>
          <li>
            Käsittelyn oikeusperuste:
            <ul>
              <li>
                nimen ja kotipaikan osalta lakisääteinen velvoite,
              </li>
              <li> 
                jäsenyyden lajin (liittyy kerättäviin maksuihin), sähköpostiosoitteen (liittyy kerättäviin maksuihin ja kokouskutsujen lähettämiseen), vyökoe-, tilaus- ja osallistumistietojen yms. (mahdollistavat yhdistyksen toiminnan tarkoitetusti) osalta sopimuksen toteuttaminen
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Yhdistyksen pöytäkirjojen ja muiden dokumenttien säilytys ja hallinnointi
        <ul>
          <li>
          Yhdistyksellä
      on velvollisuus säilyttää dokumentaatiota lain vaatimalla
      tavalla. Lisäksi yhdistyksen toimintaan liittyen saattaa olla
      luontevaa ja mielekästä esimerkiksi kerätä kuvapankkia, laatia
      esitteitä jne.
          </li>
          <li>
          Käsittelyn
      oikeusperuste: lakisääteinen velvoite, rekisteröidyn suostumus
          </li>
        </ul>
      </li>
      <li>
        Muiden lakisääteisten velvollisuuksien täyttäminen
        <ul>
          <li>
            Henkilötietoja voidaan käsitellä oikeusperusteella lakisääteinen velvoite
            <ul>
              <li>
              toiminnantarkastuksen suorittamiseksi ja
              </li>
              <li>
              kaikkien
        tähän erikseen kirjaamattomien lakisääteisten velvollisuuksien
        täyttämiseksi. Tällaisia voivat olla esimerkiksi kirjanpitolain
        vaatimukset sekä viranomaisten lakiin perustuva tietopyyntöjen
        käsittely.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Väärinkäytösten selvittäminen
        <ul>
          <li>
            Verkkopalvelinlokitietoja voidaan käyttää sivujemme laadunvarmistukseen (mahdollisten vikojen havaitsemiseen) sekä verkkohyökkäysten ja vastaavien rikosten ja väärinkäytösten selvittämiseen oikeusperusteella rekisterinpitäjän oikeutettu etu.
            <ul>
              <li>
                Asiaa koskevassa tasapainotestissä tultiin tulokseen, jonka mukaan tavanomainen palvelinlokien kerääminen
                palvelee niin verkkosivujen käyttäjien kuin rekisterinpitäjänkin etua eikä aiheuta kohtuutonta haittaa.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        Viestintä	ulkopuolisten tahojen kanssa (esim. sähköpostikyselyihin vastaaminen)
        <ul>
          <li>
            Käsittelyn oikeusperuste: rekisterinpitäjän oikeutettu etu
            <ul>
              <li>
                Asiaa
        koskevassa tasapainotestissä tultiin tulokseen, jossa
        kommunikoinnin mahdollistuminen on niin rekisterinpitäjän kuin
        rekisteröidynkin etu. Käsittelyn kohteena ovat yhteystiedot,
        joita käytetään nimenomaan siten kuin rekisteröity niitä
        todennäköisesti käytettävän olettaa. Rekisteröity hyötyy
        siitä, että yhdistys voi esimerkiksi käsitellä
        sähköpostiosoitetta vastatakseen sille osoitettuun tiedusteluun.
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    </>
  };
  
  const s4_fi = {
    heading: "Henkilötietoryhmät",
    content: <>
      Yhdistys saa tiedot pääosin rekisteröidyiltä itseltään, mutta selvyyden
  vuoksi tässä listataan, mitä henkilötietoryhmiä yhdistys saattaa
  käsitellä eri rekisteröityjen ryhmien osalta.
      <ul>
        <li>
          Yhdistyksen jäsenet:
          <ul>
            <li>
              nimi
            </li>
            <li>
              kotipaikka
            </li>
            <li>
              sähköpostiosoite
            </li>
            <li>
              jäsenyyden laji
            </li>
            <li>
              mahdollisen Nextcloud-profiilin tunnistautumistiedot
            </li>
            <li>
              mahdollisen Nextcloud-profiilin ryhmätiedot
            </li>
            <li>
              käyttäjän	itsensä mahdollisesti Nextcloudiin lisäämät tiedot
            </li>
            <li>
              vyökokeisiin liittyvät tiedot
            </li>
            <li>
              tiedot harjoituksiin ja tapahtumiin osallistumisesta
            </li>
            <li>
              tilaustiedot (esim. välinehankintojen osalta)
            </li>
          </ul>
        </li>
        <li>
          Yhdistykseen yhteyttä ottavat tahot tai heidän edustajansa
          <ul>
            <li>
              tapauskohtaiset	yhteystiedot kuten nimi, sähköpostiosoite tai puhelinnumero
            </li>
          </ul>
        </li>
        <li>
          Yhdistyksen verkkosivuilla vierailevat
          <ul>
            <li>
              tavanomaiset verkkopalvelinlokitiedot, esim. IP-osoite
            </li>
          </ul>
        </li>
        <li>
          Yhdistyksen	kannalta relevanttien sidosryhmien edustajat
          <ul>
            <li>
              tapauskohtaiset	yhteystiedot kuten nimi, sähköpostiosoite tai puhelinnumero
            </li>
          </ul>
        </li>
        <li>
          Henkilöt,	joiden henkilötietoja päätyy yhdistyksen pöytäkirjoihin tai
    muihin dokumentteihin (esim. yhdistyksen ulkopuoliset
    toiminnantarkastajat)
          <ul>
            <li>
              tapauskohtaiset	henkilötietoryhmät, esim. nimi
            </li>
          </ul>
        </li>
      </ul>
    </>
  };
  
  const s4_en = {
    content: <>
      Yhdistys saa tiedot pääosin rekisteröidyiltä itseltään, mutta selvyyden
  vuoksi tässä listataan, mitä henkilötietoryhmiä yhdistys saattaa
  käsitellä eri rekisteröityjen ryhmien osalta.
      <ul>
        <li>
          Yhdistyksen jäsenet:
          <ul>
            <li>
              nimi
            </li>
            <li>
              kotipaikka
            </li>
            <li>
              sähköpostiosoite
            </li>
            <li>
              jäsenyyden laji
            </li>
            <li>
              mahdollisen Nextcloud-profiilin tunnistautumistiedot
            </li>
            <li>
              mahdollisen Nextcloud-profiilin ryhmätiedot
            </li>
            <li>
              käyttäjän	itsensä mahdollisesti Nextcloudiin lisäämät tiedot
            </li>
            <li>
              vyökokeisiin liittyvät tiedot
            </li>
            <li>
              tiedot harjoituksiin ja tapahtumiin osallistumisesta
            </li>
            <li>
              tilaustiedot (esim. välinehankintojen osalta)
            </li>
          </ul>
        </li>
        <li>
          Yhdistykseen yhteyttä ottavat tahot tai heidän edustajansa
          <ul>
            <li>
              tapauskohtaiset	yhteystiedot kuten nimi, sähköpostiosoite tai puhelinnumero
            </li>
          </ul>
        </li>
        <li>
          Yhdistyksen verkkosivuilla vierailevat
          <ul>
            <li>
              tavanomaiset verkkopalvelinlokitiedot, esim. IP-osoite
            </li>
          </ul>
        </li>
        <li>
          Yhdistyksen	kannalta relevanttien sidosryhmien edustajat
          <ul>
            <li>
              tapauskohtaiset	yhteystiedot kuten nimi, sähköpostiosoite tai puhelinnumero
            </li>
          </ul>
        </li>
        <li>
          Henkilöt,	joiden henkilötietoja päätyy yhdistyksen pöytäkirjoihin tai
    muihin dokumentteihin (esim. yhdistyksen ulkopuoliset
    toiminnantarkastajat)
          <ul>
            <li>
              tapauskohtaiset	henkilötietoryhmät, esim. nimi
            </li>
          </ul>
        </li>
      </ul>
    </>
  };
  
  const s5_fi = {
    content: <>
      <ul>
        <li>
        Dokumenttienhallintajärjestelmäämme
    (Nextcloud) ajetaan UpCloud Oy:n (2431560-5,
    Eteläranta 12, 00130 Helsinki) palvelimella
    EU-alueella. UpCloud Oy toimii siis henkilötietojen käsittelijänä
    tarjoamalla prosessointi- ja tallennuskapasiteettia, mutta käsittely
    rajoittuu infrastruktuurin tarjoamiseen. Yhdistys hallinnoi palvelinta ja hallitsee sinne säilöttyjä dokumentteja itse tietoturvan riittävään toteuttamiseen niin teknisten ratkaisujen kuin käyttöoikeuksienkin osalta pyrkien.
        </li>
        <li>
          Lähtökohtaisesti tietoja ei luovuteta muille tahoille paitsi asianomaisille viranomaisille lain niin vaatiessa.
        </li>
      </ul>
    </>
  };
  
  const s5_en = {
    content: <>
      <ul>
        <li>
        Dokumenttienhallintajärjestelmäämme
    (Nextcloud) ajetaan UpCloud Oy:n (2431560-5,
    Eteläranta 12, 00130 Helsinki) palvelimella
    EU-alueella. UpCloud Oy toimii siis henkilötietojen käsittelijänä
    tarjoamalla prosessointi- ja tallennuskapasiteettia, mutta käsittely
    rajoittuu infrastruktuurin tarjoamiseen. Yhdistys hallinnoi palvelinta ja hallitsee sinne säilöttyjä dokumentteja itse tietoturvan riittävään toteuttamiseen niin teknisten ratkaisujen kuin käyttöoikeuksien osalta pyrkien.
        </li>
        <li>
          Lähtökohtaisesti tietoja ei luovuteta muille tahoille paitsi asianomaisille viranomaisille lain niin vaatiessa.
        </li>
      </ul>
    </>
  };
  
  
  const s7_fi = {
    heading: "Henkilötietojen säilytysajat",
    content: <>
      <ul>
        <li>
        Yhdistyksen
    jäsenen jäsenyyteen liittyviä tietoja säilytetään henkilön
    jäsenyyden keston ajan sekä jäsenyyden loputtua niin kauan kuin
    on tarpeen esimerkiksi maksujen perimiseksi. Henkilön tiedot
    poistetaan ensi tilassa, kun syitä säilyttää tietoja ei enää
    ole, viimeistään kuitenkin seuraavassa vuotuisessa jäsenluettelon
    tarkistuksessa.
        </li>
        <li>
          Yhdistyksen
    jäsenen mahdolliset tunnukset dokumenttienhallintajärjestelmään
    (Nextcloud) ja hänen profiilitietonsa poistetaan jäsenyyden
    loppuessa tai kun katsotaan, ettei tunnuksille ole tarvetta
    yhdistyksen sujuvan toiminnan ja dokumenttien saatavuuden
    varmistamiseksi.
        </li>
        <li>
          Dokumenttienhallintajärjestelmään
    säilöttävän henkilötietoja sisältävän yhdistyksen käyttöön
    tarkoitetun materiaalin, pl. pysyväisarkistoitava materiaali,
    säilyttämisestä sovitaan rekisteröityjen kanssa
    tapauskohtaisesti. Jos ei muuta sovita, materiaali poistetaan
    viimeistään jäsenyyden päätyttyä vastaavasti kuin jäsenyyteen
    liittyvät tiedot.
        </li>
        <li>
          Yhdistyksen
    toimintaan liittyvä ja itse tuotettu materiaali arkistoidaan
    pysyvästi. Pysyväisarkistoitavia asiakirjoja ovat esimerkiksi
    pöytäkirjat, toimintakertomukset ja -suunnitelmat sekä tuotetut
    esitteet.
        </li>
        <li>
          Sähköpostiosoitteita,
    puhelinnumeroita ja vastaavia yhteystietoja säilytetään niin
    kauan kuin kommunikaatiotarpeen katsotaan vaativan tai kunnes
    rekisteröity pyytää tietojensa poistamista. Harkinta
    säilytystarpeesta on aina tapauskohtaista ja riippuu esimerkiksi
    tiedon julkisuudesta ja helppoudesta hankkia uudelleen sekä
    arvioidusta kommunikointitarpeen jatkumisesta. Käytännössä voi
    olla vaikea varmistua siitä, ettei tietoja jäisi missään
    muodossa mihinkään viestintävälineeseen, vaikka esimerkiksi
    dokumenttienhallintajärjestelmästä tieto poistettaisiinkin.
    Tällainen lienee melko normaalia. Teemme kuitenkin parhaamme
    tämänkin osalta erityisesti tietojen poistamista pyydettäessä.
        </li>
        <li>
          Verkkopalvelinlokitietoja säilytetään enintään viisi vuotta tai niin kauan kuin on tarpeen esimerkiksi rikoksen selvittämiseksi.
        </li>
        <li>
          Muita
    tietoja säilytetään niin kauan kuin on tarpeen käyttötarkoitus
    ja lain vaatimukset huomioiden. (Esim. kirjanpitolaki määrää
    säilytysaikoja (6–10 a) vaikkapa tilitositteille ja
    kirjanpitokirjoille. Nämä eivät kuitenkaan pääsääntöisesti
    sisällä henkilötietoja.) Tiedot poistetaan, kun syitä
    säilyttämiselle ei enää ole.
        </li>
      </ul>
    </>
  };
  
  const s7_en = {
    heading: "Henkilötietojen säilytysajat",
    content: <>
      <ul>
        <li>
        Yhdistyksen
    jäsenen jäsenyyteen liittyviä tietoja säilytetään henkilön
    jäsenyyden keston ajan sekä jäsenyyden loputtua niin kauan kuin
    on tarpeen esimerkiksi maksujen perimiseksi. Henkilön tiedot
    poistetaan ensi tilassa, kun syitä säilyttää tietoja ei enää
    ole, viimeistään kuitenkin seuraavassa vuotuisessa jäsenluettelon
    tarkistuksessa.
        </li>
        <li>
          Yhdistyksen
    jäsenen mahdolliset tunnukset dokumenttienhallintajärjestelmään
    (Nextcloud) ja hänen profiilitietonsa poistetaan jäsenyyden
    loppuessa tai kun katsotaan, ettei tunnuksille ole tarvetta
    yhdistyksen sujuvan toiminnan ja dokumenttien saatavuuden
    varmistamiseksi.
        </li>
        <li>
          Dokumenttienhallintajärjestelmään
    säilöttävän henkilötietoja sisältävän yhdistyksen käyttöön
    tarkoitetun materiaalin, pl. pysyväisarkistoitava materiaali,
    säilyttämisestä sovitaan rekisteröityjen kanssa
    tapauskohtaisesti. Jos ei muuta sovita, materiaali poistetaan
    viimeistään jäsenyyden päätyttyä vastaavasti kuin jäsenyyteen
    liittyvät tiedot.
        </li>
        <li>
          Yhdistyksen
    toimintaan liittyvä ja itse tuotettu materiaali arkistoidaan
    pysyvästi. Pysyväisarkistoitavia asiakirjoja ovat esimerkiksi
    pöytäkirjat, toimintakertomukset ja -suunnitelmat sekä tuotetut
    esitteet.
        </li>
        <li>
          Sähköpostiosoitteita,
    puhelinnumeroita ja vastaavia yhteystietoja säilytetään niin
    kauan kuin kommunikaatiotarpeen katsotaan vaativan tai kunnes
    rekisteröity pyytää tietojensa poistamista. Harkinta
    säilytystarpeesta on aina tapauskohtaista ja riippuu esimerkiksi
    tiedon julkisuudesta ja helppoudesta hankkia uudelleen sekä
    arvioidusta kommunikointitarpeen jatkumisesta. Käytännössä voi
    olla vaikea varmistua siitä, ettei tietoja jäisi missään
    muodossa mihinkään viestintävälineeseen, vaikka esimerkiksi
    dokumenttienhallintajärjestelmästä tieto poistettaisiinkin.
    Tällainen lienee melko normaalia. Teemme kuitenkin parhaamme
    tämänkin osalta erityisesti tietojen poistamista pyydettäessä.
        </li>
        <li>
          Verkkopalvelinlokitietoja säilytetään enintään viisi vuotta tai niin kauan kuin on tarpeen esimerkiksi rikoksen selvittämiseksi.
        </li>
        <li>
          Muita
    tietoja säilytetään niin kauan kuin on tarpeen käyttötarkoitus
    ja lain vaatimukset huomioiden. (Esim. kirjanpitolaki määrää
    säilytysaikoja (6–10 a) vaikkapa tilitositteille ja
    kirjanpitokirjoille. Nämä eivät kuitenkaan pääsääntöisesti
    sisällä henkilötietoja.) Tiedot poistetaan, kun syitä
    säilyttämiselle ei enää ole.
        </li>
      </ul>
    </>
  };
  
  
  const s8_fi = {
    content: <>
      Rekisteröidyllä
    on erilaisia oikeuksia riippuen esimerkiksi tietojen käsittelyn
    oikeusperusteesta. Halutessaan käyttää oikeuksiaan rekisteröity
    voi ottaa yhteyttä osoitteeseen tietosuoja.tampere@kssr.fi ja
    ilmoittaa tahdostaan asian laittamiseksi vireille.
      <ul>
        <li>
        Rekisteröidyllä
    on oikeus <b>saada tietoa henkilötietojensa käsittelystä</b>.
    Tämä dokumentti pyrkii antamaan tällaista tietoa ymmärrettävästi
    ja riittävässä laajuudessa.
        </li>
        <li>
        Rekisteröidyllä
    on oikeus <b>saada pääsy henkilötietoihinsa</b>. Lyhyesti tämä
    tarkoittaa, että rekisterinpitäjän on pyynnöstä vahvistettava,
    käsitelläänkö pyytäjää koskevia henkilötietoja, ja jos
    käsitellään, toimitettava jäljennös näistä käsiteltävistä
    henkilötiedoista. Lisätietoa tästä oikeudesta
    tietosuojavaltuutetun sivuilta:
    https://tietosuoja.fi/oikeus-saada-paasy-tietoihin.
        </li>
        <li>
        Rekisteröidyllä
    on oikeus <b>saada tietonsa oikaistuiksi</b> vaatimalla rekisterinpitäjää oikaisemaan häntä koskevat
    virheelliset tai epätarkat tiedot sekä täydentämään
    tarvittaessa puutteelliset tiedot. Lisätietoja tästä oikeudesta
    löytyy tietosuojavaltuutetun sivuilta osoitteesta
    https://tietosuoja.fi/oikeus-oikaista-tietoja.
        </li>
        <li>
        Rekisteröidyllä
    on tietyin edellytyksin – esimerkiksi pelkän suostumuksen nojalla
    tietoja käsiteltäessä, kun suostumus peruutetaan – oikeus <b>saada
    tietonsa poistetuiksi</b>. Joissakin tapauksissa – esimerkiksi
    lain noudattamiseksi – kuitenkaan rekisterinpitäjän ei ole pakko
    poistaa tietoja. Yksityiskohtaisempia tietoja asiasta löytyy
    tietosuojavaltuutetun sivuilta osoitteesta
    https://tietosuoja.fi/kun-haluat-poistaa-tietosi.
        </li>
        <li>
        Rekisteröidyllä
    saattaa olla oikeus <b>rajoittaa tietojensa käsittelyä</b>.
    Käsittelyä rajoitettaessa tietoja saa käsitellä vain 
          <ul>
            <li>
              rekisteröidyn suostumuksella,
            </li>
            <li>
              yleisen edun vuoksi,
            </li>
            <li>
              toisen henkilön oikeuksien suojaamiseksi tai
            </li>
            <li>
              oikeusvaateen	laatimisen, esittämisen tai puolustamisen vuoksi.
            </li>
          </ul>
          Henkilötietojen	rajoittaminen on mahdollista, jos
          <ul>
            <li>
              tiedot vaikuttavat virheellisiltä,
            </li>
            <li>
              tietoja käsitellään lainvastaisesti,
            </li>
            <li>
              rekisterinpitäjä ei tarvitse tietoja, mutta rekisteröity tarvitsee niitä
      oikeusvaateen laatimiseen, esittämiseen tai puolustamiseen tai
            </li>
            <li>
              rekisteröity on vastustanut tietojensa käsittelyä, mutta päätöstä asiasta ei ole vielä tehty.
            </li>
          </ul>
    Lisätietoa löytyy
    tietosuojavaltuutetun sivuilta osoitteesta
    https://tietosuoja.fi/kun-et-halua-etta-tietojasi-kasitellaan.
        </li>
        <li>
          Rekisteröidyllä
    saattaa olla oikeus <b>vastustaa tietojensa käsittelyä</b>.
    Vastustaminen tarkoittaa sen pyytämistä, ettei tietoja
    käsiteltäisi ensinkään. Tässä tapauksessa
    vastustamismahdollisuus liittyy tilanteisiin, joissa
    rekisterinpitäjä käsittelee tietoja käyttäen oikeusperusteena
    oikeutettua etuaan. Vastustamiseen tarvitaan henkilökohtaiseen
    tilanteeseen liittyvä erityinen syy, paitsi jos kyse on
    suoramarkkinoinnista, jolloin vastustamista ei tarvitse perustella.
    Lisätietoa löytyy tietosuojavaltuutetun sivuilta osoitteesta
    https://tietosuoja.fi/kun-et-halua-etta-tietojasi-kasitellaan.
        </li>
        <li>
          Rekisteröidyllä
    saattaa olla oikeus <b>siirtää tietonsa</b> järjestelmästä
    toiseen. Käytännössä tietyillä edellytyksillä on siis
    mahdollista saada rekisteröityä itseään koskevia henkilötietoja
    yleisesti käytetyssä muodossa toiselle rekisterinpitäjälle
    toimittamista varten. Ottaen huomioon rekisterien sisällön,
    luonteen ja tarkoituksen (yhdistystoiminnan mahdollistaminen
    mielekkäästi tässä nimenomaisessa yhdistyksessä), lienee
    epätodennäköistä, että tätä oikeutta olisi mielekästä
    käyttää, mutta tarvittaessa lisätietoja löytyy
    tietosuojavaltuutetun sivuilta:
    https://tietosuoja.fi/kun-haluat-siirtaa-tietosi.
        </li>
        <li>
          Osa tietojen
    käsittelystä perustuu rekisteröidyn suostumukseen.
    Rekisteröidyllä on oikeus
    <b> peruuttaa suostumuksensa</b> milloin tahansa. Tällaisen
    peruutuksenkin voi tehdä ilmoittamalla asiasta osoitteeseen
    tietosuoja.tampere@kssr.fi.
        </li>
        <li>
          Rekisteröidyllä
    on 77 artiklan mukainen oikeus <b>tehdä ilmoitus
    valvontaviranomaiselle</b> (jollaisena Suomessa toimii
    tietosuojavaltuutettu) mikäli rekisterinpitäjä kieltäytyy
    perusteetta tietosuojapyynnön mukaisesta toiminnasta, tietoja
    käsitellään perusteetta tai rekisteröity havaitsee muun
    epäkohdan henkilötietojen käsittelyssä. Lisätietoa
    tietosuojavaltuutetun sivuilta:
    https://tietosuoja.fi/ilmoitus-tietosuojavaltuutetulle.
        </li>
      </ul>
    </>
  };
  
  const s8_en = {
    content: <>
      Rekisteröidyllä
    on erilaisia oikeuksia riippuen esimerkiksi tietojen käsittelyn
    oikeusperusteesta. Halutessaan käyttää oikeuksiaan rekisteröity
    voi ottaa yhteyttä osoitteeseen tietosuoja.tampere@kssr.fi ja
    ilmoittaa tahdostaan asian laittamiseksi vireille.
      <ul>
        <li>
        Rekisteröidyllä
    on oikeus <b>saada tietoa henkilötietojensa käsittelystä</b>.
    Tämä dokumentti pyrkii antamaan tällaista tietoa ymmärrettävästi
    ja riittävässä laajuudessa.
        </li>
        <li>
        Rekisteröidyllä
    on oikeus <b>saada pääsy henkilötietoihinsa</b>. Lyhyesti tämä
    tarkoittaa, että rekisterinpitäjän on pyynnöstä vahvistettava,
    käsitelläänkö pyytäjää koskevia henkilötietoja, ja jos
    käsitellään, toimitettava jäljennös näistä käsiteltävistä
    henkilötiedoista. Lisätietoa tästä oikeudesta
    tietosuojavaltuutetun sivuilta:
    https://tietosuoja.fi/oikeus-saada-paasy-tietoihin.
        </li>
        <li>
        Rekisteröidyllä
    on oikeus <b>saada tietonsa oikaistuiksi</b> vaatimalla rekisterinpitäjää oikaisemaan häntä koskevat
    virheelliset tai epätarkat tiedot sekä täydentämään
    tarvittaessa puutteelliset tiedot. Lisätietoja tästä oikeudesta
    löytyy tietosuojavaltuutetun sivuilta osoitteesta
    https://tietosuoja.fi/oikeus-oikaista-tietoja.
        </li>
        <li>
        Rekisteröidyllä
    on tietyin edellytyksin – esimerkiksi pelkän suostumuksen nojalla
    tietoja käsiteltäessä, kun suostumus peruutetaan – oikeus <b>saada
    tietonsa poistetuiksi</b>. Joissakin tapauksissa – esimerkiksi
    lain noudattamiseksi – kuitenkaan rekisterinpitäjän ei ole pakko
    poistaa tietoja. Yksityiskohtaisempia tietoja asiasta löytyy
    tietosuojavaltuutetun sivuilta osoitteesta
    https://tietosuoja.fi/kun-haluat-poistaa-tietosi.
        </li>
        <li>
        Rekisteröidyllä
    saattaa olla oikeus <b>rajoittaa tietojensa käsittelyä</b>.
    Käsittelyä rajoitettaessa tietoja saa käsitellä vain 
          <ul>
            <li>
              rekisteröidyn suostumuksella,
            </li>
            <li>
              yleisen edun vuoksi,
            </li>
            <li>
              toisen henkilön oikeuksien suojaamiseksi tai
            </li>
            <li>
              oikeusvaateen	laatimisen, esittämisen tai puolustamisen vuoksi.
            </li>
          </ul>
          Henkilötietojen	rajoittaminen on mahdollista, jos
          <ul>
            <li>
              tiedot vaikuttavat virheellisiltä,
            </li>
            <li>
              tietoja käsitellään lainvastaisesti,
            </li>
            <li>
              rekisterinpitäjä ei tarvitse tietoja, mutta rekisteröity tarvitsee niitä
      oikeusvaateen laatimiseen, esittämiseen tai puolustamiseen tai
            </li>
            <li>
              rekisteröity on vastustanut tietojensa käsittelyä, mutta päätöstä asiasta ei ole vielä tehty.
            </li>
          </ul>
    Lisätietoa löytyy
    tietosuojavaltuutetun sivuilta osoitteesta
    https://tietosuoja.fi/kun-et-halua-etta-tietojasi-kasitellaan.
        </li>
        <li>
          Rekisteröidyllä
    saattaa olla oikeus <b>vastustaa tietojensa käsittelyä</b>.
    Vastustaminen tarkoittaa sen pyytämistä, ettei tietoja
    käsiteltäisi ensinkään. Tässä tapauksessa
    vastustamismahdollisuus liittyy tilanteisiin, joissa
    rekisterinpitäjä käsittelee tietoja käyttäen oikeusperusteena
    oikeutettua etuaan. Vastustamiseen tarvitaan henkilökohtaiseen
    tilanteeseen liittyvä erityinen syy, paitsi jos kyse on
    suoramarkkinoinnista, jolloin vastustamista ei tarvitse perustella.
    Lisätietoa löytyy tietosuojavaltuutetun sivuilta osoitteesta
    https://tietosuoja.fi/kun-et-halua-etta-tietojasi-kasitellaan.
        </li>
        <li>
          Rekisteröidyllä
    saattaa olla oikeus <b>siirtää tietonsa</b> järjestelmästä
    toiseen. Käytännössä tietyillä edellytyksillä on siis
    mahdollista saada rekisteröityä itseään koskevia henkilötietoja
    yleisesti käytetyssä muodossa toiselle rekisterinpitäjälle
    toimittamista varten. Ottaen huomioon rekisterien sisällön,
    luonteen ja tarkoituksen (yhdistystoiminnan mahdollistaminen
    mielekkäästi tässä nimenomaisessa yhdistyksessä), lienee
    epätodennäköistä, että tätä oikeutta olisi mielekästä
    käyttää, mutta tarvittaessa lisätietoja löytyy
    tietosuojavaltuutetun sivuilta:
    https://tietosuoja.fi/kun-haluat-siirtaa-tietosi.
        </li>
        <li>
          Osa tietojen
    käsittelystä perustuu rekisteröidyn suostumukseen.
    Rekisteröidyllä on oikeus
    <b> peruuttaa suostumuksensa</b> milloin tahansa. Tällaisen
    peruutuksenkin voi tehdä ilmoittamalla asiasta osoitteeseen
    tietosuoja.tampere@kssr.fi.
        </li>
        <li>
          Rekisteröidyllä
    on 77 artiklan mukainen oikeus <b>tehdä ilmoitus
    valvontaviranomaiselle</b> (jollaisena Suomessa toimii
    tietosuojavaltuutettu) mikäli rekisterinpitäjä kieltäytyy
    perusteetta tietosuojapyynnön mukaisesta toiminnasta, tietoja
    käsitellään perusteetta tai rekisteröity havaitsee muun
    epäkohdan henkilötietojen käsittelyssä. Lisätietoa
    tietosuojavaltuutetun sivuilta:
    https://tietosuoja.fi/ilmoitus-tietosuojavaltuutetulle.
        </li>
      </ul>
    </>
  };
  
  
  function PrivacyContent(props) {
    const {t, i18n} = useTranslation();
  
    
    let s3_content = i18n.language === 'en' ? s3_en.content : (i18n.language === 'fi' ? s3_fi.content : s3_fi.content);
    let s4_content = i18n.language === 'en' ? s4_en.content : (i18n.language === 'fi' ? s4_fi.content : s4_fi.content);
    let s5_content = i18n.language === 'en' ? s5_en.content : (i18n.language === 'fi' ? s5_fi.content : s5_fi.content);
  
    const s6 = {
      content: <>
        <ul>
          <li>
            {t('privacy.s6_content')}
          </li>
        </ul>
      </>
    };
  
    let s7_content = i18n.language === 'en' ? s7_en.content : (i18n.language === 'fi' ? s7_fi.content : s7_fi.content);
    let s8_content = i18n.language === 'en' ? s8_en.content : (i18n.language === 'fi' ? s8_fi.content : s8_fi.content);
  
    //console.log("s3_content");
    //console.log(s3_content);
    //console.log(s4_content);
    
  
    let cont = <div className="text-content">
        <h2>{t('privacy.h')}</h2>
        <ExpandableSection heading={t('privacy.lead_heading')} content={t('privacy.lead_content')} showInitially={true} noExpandSymbol={true}/>
        <p className="privacy-summary">
          <em>
          {t('privacy.summary')}
          </em>
        </p>
        <ExpandableSection heading={t('privacy.s2_heading')} content={t('privacy.s2_content')} />
        <ExpandableSection heading={t('privacy.s3_heading')} content={s3_content} />
        <ExpandableSection heading={t('privacy.s4_heading')} content={s4_content} />
        <ExpandableSection heading={t('privacy.s5_heading')} content={s5_content} />
        <ExpandableSection heading={t('privacy.s6_heading')} content={s6.content} />
        <ExpandableSection heading={t('privacy.s7_heading')} content={s7_content} />
        <ExpandableSection heading={t('privacy.s8_heading')} content={s8_content} />
        <ExpandableSection heading={t('privacy.s9_heading')} content={t('privacy.s9_content')} />
        <ExpandableSection heading={t('privacy.s10_heading')} content={t('privacy.s10_content')} />
        <ExpandableSection heading={t('privacy.s11_heading')} content={t('privacy.s11_content')} />
        <div><br></br></div>
    </div>;
  
    let langContent = cont; 
  
    return (
      langContent
    );
  };

export default PrivacyContent;