//import {useTranslation} from 'react-i18next';   
//import WithLanguageSupport from './with_language_support';   
function PracticeContent(props) {
    const t = props.t;
   
    return(
      <div className="text-content">
        <h2>{t('practice.h1')}{/*Harjoittelu Tampereella ja muualla*/}</h2>
        <div className="columned">
          <div className="avoid-break">           
            <h3 style={{marginTop: 0}}>{t('practice.h1_')}{/*Tilanne Suomessa (pähkinänkuoressa)*/}</h3>
            <p>  
              {t('practice.p1_1')} <a href={t('practice.hki_addr')}>{t('practice.p1_2')}</a>. {t('practice.p1_3')}
            </p>
            <p className="avoid-break">
              {t('practice.p2')}
            </p>
          </div>
          <div className="avoid-break">
          <h3>{t('practice.h2')}{/*Miten lajia harjoitellaan?*/}</h3>
          <p>
            {t('practice.p3')}
          </p>
          </div>
          <div className="avoid-break">
          <h3>{t('practice.h3')}{/*Kenelle?*/}</h3>
          <p>
            {t('practice.p4')} 
          </p>
          <p>
            {t('practice.p4_')} 
          </p>
          </div>
          <div className="avoid-break">
          <h3>{t('practice.h4')}{/*Miten mukaan?*/}</h3>
          <p>
            {t('practice.p5')}
          </p>
          <p>
            {t('practice.p6')}
          </p>
          </div>
        </div>
        {/*
        <div>
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        */}
      </div>
    );
};

export default PracticeContent;
/*
const PWLS = WithLanguageSupport(PracticeContent)();
console.log(typeof(PWLS));
export default PWLS;
*/