const en = {
  translation: {
    "general": {
      "art_name": "Kashima-Shinryū",
      "practicing": "Practicing",
      "privacy": "Privacy",
      "contact": "Contact",
      "municipality": "Tampere Chapter",
      "country": "Finland",
      "updated": "Pages updated: ",
      "update_date": "July 8, 2023",
      "email": "tampere@kssr.fi",
      "author": "Kashima-Shinryū Tampere ry"
    },
    "main": {
      "h1": "Kashima-Shinryū",
      "p1": "Kashima-Shinryū (鹿島神流) is a traditional Japanese school (a ryūha) of samurai martial skills (bugei). The tradition – one of the oldest providing systematic training to samurai warriors – originates from the early Sengoku period.",
      "deleted": "Several other schools such as Kashima Shintō-ryū, Kashima Shinden Jikishinkageryū, and Yagyū Shinkage­ryū can be considered to be branched from it",
      "p2": "Because Kashima-Shinryū is a wholistic sōgō bujutsu system, its curriculum teaches a wide variety of weapons as well as unarmed combat skills. One always begins by practicing swordsmanship (kenjutsu) and unarmed combat skills (jūjutsu).",
      "p3": "The practice aims at familiarizing the practitioner with fundamental core principles characteristic to the school. These common principles can later be applied to using other weapons – introduced and included in the practice as one keeps developing – as well." 
    },
    "practice": {
      "h1": "Practicing in Tampere and Elsewhere",
      "h1_": "Kashima-Shinryū in Finland",
      "p1_1": "Besides Japan, there are legitimate chapters (authorized by Kashima-Shinryū Federation of Martial Sciences) also in USA and Europe. In Finland, there are two of them; it is possible to practice in Tampere or in ",
      "p1_2": "Helsinki",
      "p1_3": "Teaching Kashima-Shinryū began at University of Helsinki in the summer of 1996, and practicing became possible also at Tampere University of Technology in the spring of 2002. At the moment, there are more than 25 Finnish persons (including several menkyo kaidens) having a rank in Kashima-Shinryū.",
      "p2": "The practitioners of the Tampere and Helsinki chapters regularly practice together, organize common special practice sessions, and cooperate closely also by other means. Close and friendly relations are also maintained with other chapters around the world.",
      "h2": "How is it Practiced?",
      "p3": "Kashima-Shinryū practice is based on kata. They are performed as pairs of a teacher and a student. Oral initiations (kuden) are an essential part of the transmission of the art. A Kashima-Shinryū-style (straight and heavy) wooden sword (bokutō) can be seen as the most important piece of the essential training equipment.",
      "h3": "For Whom?",
      "p4": "The most important characteristics of a good potential practitioner of Kashima-Shinryū are good character and genuine interest towards the art. Typically, beginners are university students – the art is mainly taught in university / research center dōjōs around the world. In Tampere, we require the minimum age of 18 years (legal adulthood). Any maximum age has not been defined.",
      "p4_": "Having earlier budō experience is not required. Please note that practicing other martial arts simultaneously is generally not an option; once started, one should commit oneself to Kashima-Shinryū – it is a comprehensive system with its own principles, and parallel study of other arts might hinder learning considerably.",
      "h4": "How to get Aboard?",
      "p5": "Nowadays, practice in Tampere takes place in the Nääshalli premises. Because Kashima-Shinryū is taught using traditional methodology, we may have to limit the number of newcomers at any given time; any \"beginners' courses\" for masses are not organized.",
      "p6": "However, teaching resources allowing, new practitioners can be admitted round the year. If you are interested in starting practicing Kashima-Shinryū in Tampere, please do not hesitate to contact us! Let us see what the current situation is and when it would be possible to join to observe our practice session. (This is the recommended first step that lets you make a more informed decision about whether you want to apply for actually getting started or not.)",
      "hki_addr": "https://www.kashima-shinryu.fi/"
    },
    "privacy": {
      "h": "Privacy Policy (December 27, 2022)",
      "lead_heading": "",
      "lead_content": "Detailed privacy information is, at the moment, only available in Finnish. Your data is handled reasonably and in accordance with the legistlation and common sense, though.",
      "s2_heading": "Controller",
      "s2_content": "Tässä dokumentissa käsitelty rekisterinpitäjänä toimiva organisaatio on Kashima-Shinryū Tampere ry (3083099-6). Sillä ei ole erikseen nimettyä tietosuojavastaavaa, mutta tarvittaessa yhteyttä voi ottaa osoitteella tietosuoja.tampere@kssr.fi.",
      "s3_heading": "Purposes and legal bases for handling personal data",
      "s3_content": "NOT IN USE",
      "s4_heading": "Categories of personal data handled",
      "s4_content": "NOT IN USE",
      "s5_heading": "To whom data can be disclosed",
      "s5_content": "",
      "s6_heading": "Transferring data to third countries",
      "s6_content": "Tietoja ei lähtökohtaisesti siirretä kolmansiin maihin eikä kansainvälisille järjestöille. Mahdolliset siirrot tapahtuvat noudattaen lainsäädäntöä. (Jos jostakin syystä UpCloud Oy siirtää tietoja EU-/ETA-maiden ulkopuolelle maahan, jonka tietosuojatason Euroopan komissio katsoo riittämättömäksi, tietosuojasta huolehditaan soveltamalla tietosuojaa koskevia 46.2 artiklan mukaisia vakiosopimuslausekkeita tai muita säännösten sallimia turvatoimenpiteitä.)",
      "s7_heading": "Applied data storage time limitations",
      "s7_content": "",
      "s8_heading": "Rights of data subjects",
      "s8_content": "",
      "s9_heading": "Obligations to disclose data to us",
      "s9_content": "Yhdistyksen jäsenten osalta nimi ja kotipaikka vaaditaan lakisääteiseen jäsenluetteloon. Nämä tiedot ovat siis välttämättömiä ja niiden antamisen voidaan katsoa olevan lakisääteisiä vaatimuksia ja sopimuksen tekemisen edellyttämiä vaatimuksia yhdistykseen liityttäessä. Tietoa jäsenyyden lajista voidaan pitää sopimuksen tekemisen edellyttämänä vaatimuksena varsinaiseksi jäseneksi tai kannatusjäseneksi liityttäessä, koska asia saattaa vaikuttaa esimerkiksi kerättäviin maksuihin ja kokousteknisiin oikeuksiin. Myös sähköpostiosoitteen antamista voidaan jäsenten osalta pitää sopimuksen tekemisen edellyttämänä vaatimuksena, koska sitä tarvitaan mm. jäsenmaksuasioiden hoitamiseen ja kokouskutsujen toimittamiseen. Muiden henkilötietojen antaminen on vapaaehtoista.",
      "s10_heading": "Sources of personal data",
      "s10_content": "Henkilötiedot kerätään jäsenten osalta rekisteröidyiltä itseltään pl. mahdolliset dokumenttienhallintajärjestelmän kirjautumistiedot, jotka yhdistys luo tarvittaessa käyttäjille itse. Yhdistykseen sen ulkopuolelta kohdistuva viestintä saattaa kerryttää henkilötietoja (ulkopuolisten tahojen tai niiden edustajien yhteystietoja). Yhteystietoja saatetaan myös etsiä julkisista lähteistä.",
      "s11_heading": "Automated decision-making and profiling",
      "s11_content": "Henkilötietoja ei käytetä automaattiseen päätöksentekoon eikä profilointiin.",
      "summary": ""
    },
    "contact": {
      "h": "Contacting Us",
      "p1_1": "If you are interested in possibilities to start practicing this interesting, traditional art in Tampere, please contact us using the email address ",
      "p1_2": " and tell us a little bit about yourself. Other enquiries are welcome, also.",
      "p2": "We can also be contacted via the following form. (Filling all the fields properly is required to get the message delivered. After a succesful submission, you should see a clear confirmation message. If not, please contact us by email!)",
      "name": "Name:",
      "email": "Email address:",
      "msg": "Message:",
      "chars_left": "characters left",
      "humanity_check": "Humanity check:",
      "hum_check_instr": "Write \"naginata\" backwards. Add a comma in the middle of the string.",
      "submit": "Submit",
      "sending": "Sending...",
      "ok": "The message seems to have been submitted successfully. We'll get back to you as soon as possible!",
      "nok": "Unfortunately, the message was not delivered due to some mysterious problem... Please contact us via email. Sorry!",
      "nokhum": "Unfortunately, you did not pass the humanity check. The message was not delivered.",
      "links_heading": "Links",
      "kssr_budo_renmei_text": "Kashima-Shinryū Budō Renmei",
      "kssr_budo_renmei_explanation": " – the official pages of Kashima-Shinryū Federation of Martial Sciences",
      "kssr_budo_renmei_address": "http://www.kashima-shinryu.jp/English/index.html",
      "kssr_hki_text": "Kashima-Shinryū Finland – Helsinki Chapter",
      "kssr_hki_address": "http://www.kashima-shinryu.fi/"
    }
  }
}
export default en;