import React, {/*useEffect,*/ useContext} from 'react';
import {
  Nav,
  NavLink,
  NavMenu
} from './navbarElements';
import {useTranslation} from 'react-i18next';
//import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { UserContext } from './glob_context';


/*
const onHamburgerClick = () => {
  alert('kissa');
}
*/

//<Bars onClick={onHamburgerClick}/>

const Navbar = (props) => {
  //eslint-disable-next-line no-unused-vars
  const [globLang, setGlobLang, globPage, setGlobPage] = useContext(UserContext);
  const {t/*, i18n*/} = useTranslation();
  const history = useHistory();

  const languageSelected = (lang) => {
    //console.log(lang);
    if(lang === 'fi' || lang === 'en' /*|| lang === 'test' || lang === 'foo'*/) {
      /*i18n.changeLanguage(lang).then(
        setGlobLang(lang).then(setTimeout(()=>{history.push('/' + lang +'/' + globPage)}, 0))
      );*/
      setTimeout(()=>{history.push('/' + lang +'/' + globPage)}, 0)
      
      //i18n.changeLanguage(lang).then(setGlobLang(lang)).then(setTimeout(()=>{history.push('/' + lang +'/' + globPage)}, 0));
    }
  }

  return (
    <>
      <Nav>
        <NavMenu>
          <NavLink to={'/' + globLang + '/main'} activeStyle={{fontWeight: "bold"}}>
            {t('general.art_name')}
          </NavLink>
          <NavLink to={'/' + globLang + '/practice'} activeStyle={{fontWeight: "bold"}}>
            {t('general.practicing')}
          </NavLink>
          {/*
          <NavLink to={'/' + globLang + '/privacy'} activeStyle={{fontWeight: "bold"}}>
            {t('general.privacy')}
          </NavLink>
          */}
          <NavLink to={'/' + globLang + '/contact'} activeStyle={{fontWeight: "bold"}}>
            {t('general.contact')}
          </NavLink>
        </NavMenu>
        <div id="langButtons">
          
            <button id="fibtn" onClick={()=>languageSelected('fi')} style={globLang === 'fi' ? {color: "lightgreen"} : {}}>FI</button>
          
            <button id="enbtn" onClick={()=>languageSelected('en')} style={globLang === 'en' ? {color: "lightgreen"} : {}}>EN</button>
          
            {/*
            <button id="testbtn" onClick={()=>languageSelected('test')} style={globLang === 'test' ? {color: "lightgreen"} : {}}>TEST</button>
          
            <button id="foobtn" onClick={()=>languageSelected('foo')} style={globLang === 'foo' ? {color: "lightgreen"} : {}}>FOO</button>
            */}
        </div>
      </Nav>
    </>
  );
};
  
export default Navbar;