//import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
  
export const Nav = styled.nav`
  opacity: 1.0;
  height: 6rem;
  display: flex;

  justify-content: space-between;
  padding: 0;
  z-index: 12;
  @media screen and (max-width: 845px) {
    height: 12rem;
  }
`;
  
export const NavLink = styled(Link)`
/*margin-top: -1px;
margin-left: -1px;
border: 1px solid black;*/
opacity: 1.0;
color: #bbbbbbff;
text-shadow:
  0 0 3px #bbbbbbff,
  0 0 5px #999999ff,
  0 0 7px #666666ff;

display: flex;
align-items: center;
text-align: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
/*width: 6em;*/
/*cursor: url('/tanto_cursor.png'), auto;*/
&.active {
  color: #ffffffff;
  text-shadow:
    0 0 3px #ffffff00,
    0 0 5px #ffffff88,
    0 0 7px #ffffffcc;
}
:hover {
  text-shadow:
    0 0 3px #ffffff00,
    0 0 5px #ffffff88,
    0 0 7px #ffffffcc;
}
`;
  
/*
export const Bars = styled(FaBars)`
  display: none;
  color: #808080;
  @media screen and (max-width: 845px) {
    display: block;
    position: absolute;
    top: 10vh;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
*/
  
export const NavMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: -24px;
  padding-left: 1.5rem;
  background-color: rgba(0, 0, 0, 0.45);

  @media screen and (max-width: 845px) {
    /*display: none;*/
    flex-direction: column;
    align-items: left;
  }
`;