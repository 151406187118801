//import {useTranslation} from 'react-i18next';   
import ContactForm from './ContactForm';
import e from './e.png'
  
function ContactContent(props) {
  const t = props.t;
   
  return (
    <div className="text-content">
      <h2>{t('contact.h')}</h2>
      <p>
        {t('contact.p1_1')} <img className="emimg" alt="address in pictorial form" src={e}></img> {t('contact.p1_2')}
      </p>
      <p>
        {t('contact.p2')}
      </p>
      <ContactForm t={t}/>
      
      <br></br>
      {/*
      <h2>{t('contact.links_heading')}</h2>
      <ul>
        <li><a href={t('contact.kssr_budo_renmei_address')}>{t('contact.kssr_budo_renmei_text')}</a>{t('contact.kssr_budo_renmei_explanation')}</li>
        <li><a href={t('contact.kssr_hki_address')}>{t('contact.kssr_hki_text')}</a></li>
      </ul>
      */}
      <br></br>
    </div>
  );
};
  
export default ContactContent;