const fi = {
  translation: {
    "general": {
      "art_name": "Kashima-Shinryū",
      "practicing": "Harjoittelu",
      "privacy": "Tietosuoja",
      "contact": "Yhteys",
      "municipality": "Tampere",
      "country": "Finland",
      "updated": "Sivuja päivitetty: ",
      "update_date": "8.7.2023",
      "email": "tampere@kssr.fi",
      "author": "Kashima-Shinryū Tampere ry"
    },
    "main": {
      "h1": "Kashima-Shinryū",
      "p1": "Kashima-Shinryū (鹿島神流) on sengoku-kaudella alkunsa saanut perinteikäs japanilainen kamppailulaji / samuraiden taistelutaitojen (bugei) ­koulukunta (ryūha). Se on eräs vanhimmista samuraitaitoja järjestelmällisesti kouluttaneista organisaatioista.",
      "deleted": ", ja esimerkiksi Kashima Shintō-ryūn, Kashima Shinden Jikishinkageryūn sekä Yagyū Shinkage­ryūn voidaan katsoa haarautuneen siitä",
      "p2": "Kyseessä on kokonaisvaltainen sōgō bujutsu -laji, joka opettaa niin useiden erilaisten aseiden käyttöä kuin aseettomia taitojakin. Harjoittelu aloitetaan aina miekan käyttämisen (kenjutsu) ja aseettoman kamppailun (jūjutsu) opettelusta.",
      "p3": "Kenjutsun ja jūjutsun kautta opittavia lajinomaisia perusperiaatteita voidaan soveltaa yleisemminkin; harjoittelijan edistyessä voidaan opiskeltavien aseiden määrää hiljalleen kasvattaa."
    },
    "practice": {
      "h1": "Harjoittelu Tampereella ja muualla",
      "h1_": "Tilanne Suomessa",
      "p1_1": "Kashima-Shinryū-saleja on Japanin lisäksi USA:ssa ja Euroopassa. Suomessa lajia opetetaan Tampereen lisäksi ",
      "p1_2": "Helsingissä",
      "p1_3": "Opetus alkoi Helsingin yliopiston salilla kesällä 1996, ja Tampereen teknillisellä yliopistolla harjoittelu mahdollistui keväällä 2002. Tällä hetkellä yli 25 suoma­lai­sella on lajissa vyöarvo. Näihin sisältyy jopa useita menkyo kaiden -arvoja.",
      "p2": "Tampereen ja Helsingin dōjōjen jäsenet vierailevat säännöllisesti toistensa harjoituksissa ja tekevät muutenkin tiivistä yhteistyötä. Luonnollisesti Suomesta käsin pyritään pitämään hyviä suhteita ja yhteistoimintaa yllä muidenkin legitiimien salien kanssa, osallistumaan kansainvälisille leireille jne.",
      "h2": "Miten lajia harjoitellaan?",
      "p3": "Opetusmetodina toimii pääasiassa katamuotoinen opettaja–oppilas-pariharjoittelu. Käytännön harjoitteluun nivoutuvan tradition suullisen välittämisen (kuden) rooli on merkittävä. Tärkeimpänä harjoitteluvälineenä toimii lajille tyypillinen tukeva ja melko raskas (oikeaa katanaa mitoiltaan ja massaltaan varsin hyvin vastaava) puumiekka (bokutō).",
      "h3": "Kenelle?",
      "p4": "Hyvän harjoittelijakandidaatin tärkeimpiä ominaisuuksia ovat hyvä ja rehti asenne ja aito kiinnostus lajia kohtaan. Japanissa (ja muuallakin maailmassa) Kashima-Shinryū-toiminta keskittyy yliopistojen ja tutkimuslaitosten dōjōille, ja harjoittelu usein aloitetaankin opiskelun yhteydessä. Tampereella edellytämme harjoittelijoiltamme vähintään 18 vuoden ikää (täysi-ikäisyyttä). Harrastus sopii kuitenkin mainiosti varttuneemmillekin.",
      "p4_": "Aiempaa budō-taustaa ei välttämättä tarvita. Kannattaa myös huomata, että muiden kamppailulajien samanaikainen harrastaminen ei yleensä tule kyseeseen – Kashima-Shinryū on kokonaisvaltainen järjestelmä omine periaatteineen, ja rinnalla harjoitettavat muut lajit saattaisivat häiritä ja hidastaa oppimista oleellisesti.",
      "h4": "Miten mukaan?",
      "p5": "Nykyään Tampereella Kashima-Shinryūta opetetaan Nääshallissa Kashima-Shinryū Tampere ry:n varaamilla vuoroilla. Kyseessä ei ole massalaji; käytämme perinteistä opetus­metodo­lo­gi­aa, minkä vuoksi uusia harjoittelijoita ei voi kerralla olla suurta määrää. Opetusta ei myöskään anneta ansiotarkoituksessa. Näistä syistä emme järjestä esimerkiksi \"alkeis­kurs­seja\" tai vastaavia.",
      "p6": "Pyrimme kuitenkin ottamaan täysi-ikäisiä, asiallisia ja lajista aidosti kiinnostuneita uusia harjoit­te­li­joita mukaan aina opetus­resurs­sien salliessa. Kiinnostuneita pyydämme ottamaan rohkeasti yhteyttä! Katsotaan, mikä on tilanteemme ja koska olisi mahdollista päästä seuraamaan harjoituksiamme – tämä on suositeltava ensiaskel ennen varsinaista harjoittelun aloittamista.",
      "hki_addr": "https://www.kashima-shinryu.fi/"
    },
    "privacy": {
      "h": "Tietosuojaseloste (27.12.2022)",
      "lead_heading": "",
      "lead_content": "Jotta Kashima-Shinryū Tampere ry (\"yhdistys\") voisi toimia mielekkäästi ja lain edellyttämällä tavalla, sen on pidettävä yllä henkilörekistereitä ja käsiteltävä henkilötietoja. Esimerkiksi yhteydenottoon vastataksemme saatamme joutua käyttämään (käsittelemään) vaikkapa sähköpostiosoitetta, ja jäsentemme osalta laki velvoittaa meitä pitämään yllä tietoa heidän nimistään ja kotipaikoistaan. Tässä tietosuojaselosteessa kerrotaan tällaisesta henkilötietojen käsittelemisestä.",
      "s2_heading": "Rekisterinpitäjä",
      "s2_content": "Rekisterinpitäjänä toimii Kashima-Shinryū Tampere ry (3083099-6). Sillä ei ole erikseen nimettyä tietosuojavastaavaa, mutta tarvittaessa yhteyttä voi ottaa osoitteella tietosuoja.tampere@kssr.fi.",
      "s3_heading": "Henkilötietojen käsittelyn tarkoitukset ja oikeusperusteet",
      "s3_content": "-",
      "s4_heading": "Henkilötietoryhmät",
      "s4_content": "-",
      "s5_heading": "Henkilötietojen vastaanottajaryhmät",
      "s5_content": "-",
      "s6_heading": "Tietojen siirto kolmansiin maihin",
      "s6_content": "Tietoja ei lähtökohtaisesti siirretä kolmansiin maihin eikä kansainvälisille järjestöille. Mahdolliset siirrot tapahtuvat noudattaen lainsäädäntöä. (Jos jostakin syystä UpCloud Oy siirtää tietoja EU-/ETA-maiden ulkopuolelle maahan, jonka tietosuojatason Euroopan komissio katsoo riittämättömäksi, tietosuojasta huolehditaan soveltamalla tietosuojaa koskevia 46.2 artiklan mukaisia vakiosopimuslausekkeita tai muita säännösten sallimia turvatoimenpiteitä.)",
      "s7_heading": "Henkilötietojen säilytysajat",
      "s7_content": "-",
      "s8_heading": "Rekisteröidyn oikeudet",
      "s8_content": "-",
      "s9_heading": "Velvollisuus antaa henkilötietoja",
      "s9_content": "Yhdistyksen jäsenten osalta nimi ja kotipaikka vaaditaan lakisääteiseen jäsenluetteloon. Nämä tiedot ovat siis välttämättömiä ja niiden antamisen voidaan katsoa olevan lakisääteisiä vaatimuksia ja sopimuksen tekemisen edellyttämiä vaatimuksia yhdistykseen liityttäessä. Tietoa jäsenyyden lajista voidaan pitää sopimuksen tekemisen edellyttämänä vaatimuksena varsinaiseksi jäseneksi tai kannatusjäseneksi liityttäessä, koska asia saattaa vaikuttaa esimerkiksi kerättäviin maksuihin ja kokousteknisiin oikeuksiin. Myös sähköpostiosoitteen antamista voidaan jäsenten osalta pitää sopimuksen tekemisen edellyttämänä vaatimuksena, koska sitä tarvitaan mm. jäsenmaksuasioiden hoitamiseen ja kokouskutsujen toimittamiseen. Muiden henkilötietojen antaminen on vapaaehtoista.",
      "s10_heading": "Henkilötietojen lähteet",
      "s10_content": "Henkilötiedot kerätään jäsenten osalta rekisteröidyiltä itseltään pl. mahdolliset dokumenttienhallintajärjestelmän kirjautumistiedot, jotka yhdistys luo tarvittaessa käyttäjille itse. Yhdistykseen sen ulkopuolelta kohdistuva viestintä saattaa kerryttää henkilötietoja (ulkopuolisten tahojen tai niiden edustajien yhteystietoja). Yhteystietoja saatetaan myös etsiä julkisista lähteistä.",
      "s11_heading": "Automaattinen päätöksenteko ja profilointi",
      "s11_content": "Henkilötietoja ei käytetä automaattiseen päätöksentekoon eikä profilointiin.",
      "summary": "TL;DR: Pyrimme käsittelemään henkilötietoja asian- ja lainmukaisesti ja kaikkiaan siten kuin olettaa voisi."
    },
    "contact": {
      "h": "Yhteyden ottaminen",
      "p1_1": "Jos tämän mielenkiintoisen, perinteisen lajin harjoittelu Tampereella kiinnostaa, kannattaa kirjoittaa osoitteeseen",
      "p1_2": " ja kertoa hieman itsestään. Muissakin merkeissä saa toki ottaa yhteyttä.",
      "p2": "Viestin voi lähettää myös seuraavan lomakkeen kautta. (Kaikkien kenttien asianmukainen täyttäminen vaaditaan, jotta viesti välittyisi perille. Jos viestin lähetyksestä ei jostain syystä tule selvää onnistumisilmoitusta, kannattaa ottaa yhteyttä sähköpostitse.)",
      "name": "Nimi:",
      "email": "Sähköpostiosoite:",
      "msg": "Viesti:",
      "chars_left": "merkkiä jäljellä",
      "humanity_check": "Ihmisyystarkastus:",
      "hum_check_instr": "Kirjoita \"naginata\" lopusta alkuun. Lisää vielä pilkku keskelle merkkijonoa.",
      "submit": "Lähetä",
      "sending": "Lähetetään...",
      "ok": "Lähettäminen vaikutti onnistuvan. Pyrimme vastaamaan ensi tilassa!",
      "nok": "Valitettavasti ilmeni mystinen ongelma, eikä viestiä saatu välitettyä – kannattanee lähettää sähköpostia. Pahoittelut!",
      "nokhum": "Ihmisyystarkastus jätti aihetta epäillä robottiutta. Viestiä ei välitetty.",
      "links_heading": "Linkkejä",
      "kssr_budo_renmei_text": "Kashima-Shinryū Budō Renmei",
      "kssr_budo_renmei_explanation": " -federaation viralliset sivut",
      "kssr_budo_renmei_address": "http://www.kashima-shinryu.jp/English/index.html",
      "kssr_hki_text": "Kashima-Shinryū Finland – Helsinki",
      "kssr_hki_address": "http://www.kashima-shinryu.fi/"
    }
  }
};
export default fi;
